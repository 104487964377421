import React, { useEffect, useState } from "react"
import "./style.css"

import logo from "../../../assets/images/logocarterinha.png"
import ass from "../../../assets/images/ass.png"
import userDefault from "../../../assets/images/user.png"
import api from "../../../Services/api"
import Url_Image from "../../../assets/URL_Image/Url_Image"

export default function CarteirinhaPdfVert() {
  const locale = "pt-BR"
  let imageUrl = ""
  const [alunoData, setAlunoData] = useState("")
  const [user, setUser] = useState("")

  useEffect(() => {
    const carterinhaInfo = JSON.parse(localStorage.getItem("carterinhaInfo"));

    async function getDataUser() {
      try {
        if (carterinhaInfo && carterinhaInfo.rm) {
          const imageUrl = Url_Image(carterinhaInfo.rm)
          const response = await fetch(imageUrl);
          if (!response.ok) {
            setUser("undefined");
          }

          const blob = await response.blob();
          const url = await URL.createObjectURL(blob);
          setUser(url);
        }
      } catch (error) {
        setUser("undefined");
        console.error(error);
      }
    }
    getDataUser();
  }, []);
  useEffect(() => {
    const carterinhaInfo = JSON.parse(localStorage.getItem("carterinhaInfo"));
    // console.log(carterinhaInfo);
    if (carterinhaInfo) {
      setAlunoData({
        Nome_aluno: carterinhaInfo.nome,
        Curso: carterinhaInfo.curso,
        Validede: carterinhaInfo.validade,
        Convenio: carterinhaInfo.convenio,
        Foto: carterinhaInfo.foto,
        Perfil: carterinhaInfo.perfil
      });
    }
  }, []);

  return (
    <div className="a">
      <div className="circlexx" style={{ backgroundColor: "red" }}></div>
      <div className="quadros">
        <img src={alunoData.Foto} alt="perfil" className="as" />
      </div>
      <div className="infocardxxx">
        <h1 className="names">{alunoData.Perfil}{alunoData.Nome_aluno}</h1>
        <div className="cardsss">
          <div className="columns1">
            <p>Curso</p>
            <p style={{ textTransform: "uppercase", fontSize: 12, fontWeight: "800", whiteSpace: "nowrap" }}>
              {alunoData.Curso}
            </p>
          </div>
          <div className="columns1">
            <p>Validade</p>
            <p style={{ color: "red", fontWeight: "700" }}>
              {new Date(alunoData.Validede).toLocaleDateString(locale)}
            </p>
          </div>
        </div>
      </div>
      <div className="imgames">
        <img src={logo} alt="logo" className="logofiec" />
        <img src={ass} alt="assinatura" className="assinatura" />
      </div>
      <div className="logoname">
        <p style={{ fontWeight: "600" }}>Prefeitura Municipal De Indaiatuba</p>
      </div>
      <p className="paragrafos">
        Este cartão é de uso estritamente pessoal e intransferível.O acesso a escola somente será permitido mediante
        apresentação desse cartão ou autorização da secretaria.
      </p>
      <div className="convenioxx" style={{ backgroundColor: "red" }}>
        <p className="expo">{alunoData.Convenio}</p>
      </div>
    </div>
  )
}
