import styled from "styled-components"

export const Contanier = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(3px);
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`
export const Card = styled.div`
  width: 30vw;
  height: 70vh;
  border-radius: 10px;
  background-color: white;
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.5);
  @media (max-width: 700px) {
    width: 72%;
    margin-right: 12%;
    height: 66vh;
  }
`
export const Title = styled.h1`
  font-size: 1.8rem;
  margin-top: 6%;
  font-weight: 300;
  text-align: center;
  margin-left: 21%;
  @media (max-width: 700px) {
    margin-left: 2%;

    font-size: 1.6rem;
  }
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10%;
`

export const Forms = styled.div`
  margin-top: 8%;
  display: flex;
  flex-direction: column;
  height: 60%;
  gap: 2.5rem;
  justify-content: start;
  align-items: center;
`

export const Buttons = styled.button`
  width: 10%;
  height: 70%;
  margin-left: 10%;
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin-top: 6%;
  transition: 0.2s ease-in-out;
  &:hover {
    transform: translateY(-7px);
  }
`
