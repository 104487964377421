import React, { useEffect, useState } from "react"
import "./CartVertistyle.css"
import logo from "../../../assets/images/logocarterinha.png"
import userDefault from "../../../assets/images/user.png"
import ass from "../../../assets/images/ass.png"
import api from "../../../Services/api"
import Url_Image from "../../../assets/URL_Image/Url_Image"

export default function CarteirinhaVertiPdf() {
  const locale = "pt-BR"
  const [user, setUser] = useState("")
  const [alunoData, setAlunoData] = useState([])

  let imageUrl = ""

  useEffect(() => {
    const token = localStorage.getItem("token")
    async function getDataUser() {
      api
        .get("/aluno/perfil", token)
        .then(async (response) => {
          setAlunoData(response.data)
          imageUrl = Url_Image(response.data.rm)
          setUser(imageUrl)
          try {
            const response = await fetch(imageUrl)
            if (!response.ok) {
              setUser("undefined")
            }
            const blob = await response.blob()
            const url = URL.createObjectURL(blob)
            setUser(url)
          } catch (error) {
            setUser("undefined")
            console.error(error)
          }
        })
        .catch((err) => {
          setUser("undefined")
          console.log(err)
        })
    }
    getDataUser()
  }, [])
  return (
    <div className="globalMobilepdf">
      <div className="circleMobilepdf" style={{ backgroundColor: alunoData.cor_convenio }}></div>
      <div className="quadrosMobilepdf">
        <img src={user == "undefined" || !user ? userDefault : user} alt="perfil" className="perfilfotoMobile" />
      </div>
      <div className="infocardMobilepdf">
        <h1 className="namesMobilepdf">{alunoData.nome_aluno}</h1>
        <div className="cardsssMobilepdf">
          <div className="columnsMobilepdf">
            <p>RM</p>
            <p style={{ fontWeight: 700 }}>{alunoData.rm}</p>
          </div>
          <div className="columnsMobileCpdf" >
            <p>Curso</p>
            <p className="nomeCursoMobilepdf">
              {alunoData.nome_curso}
            </p>
          </div>
          <div className="columnsMobilepdf">
            <p>Validade</p>
            <p style={{ color: "red", fontWeight: "700" }}>
              {new Date(alunoData.dt_termino).toLocaleDateString(locale)}
            </p>
          </div>
        </div>
      </div>
      <div className="imgamesMobilepdf" >
        <img src={logo} alt="logo" className="logofiec" />
        <img src={ass} alt="assinatura" className="assinatura" />
      </div>
      <div className="logonameMobilepdf">
        <p style={{ fontWeight: "600" }}>Prefeitura Municipal De Indaiatuba</p>
      </div>
      <p className="paragrafosMobilepdf" >
        Este cartão é de uso estritamente pessoal e intransferível. O acesso a escola somente será permitido mediante
        apresentação desse cartão ou autorização da secretaria.
      </p>
      <div className="convenioMobilepdf" style={{ backgroundColor: alunoData.cor_convenio }}>
        <p className="expoMobilepdf">{alunoData.convenio}</p>
      </div>
    </div>
  )
}
