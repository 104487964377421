import React, { useEffect, useState } from "react";
import { Background, Close, Container, Content, DivContent, DivMain, Header, PreviewImage, Title } from "./style";
import Button from "../../Button";
import api from "../../../Services/api";
import Url_Image from "../../../assets/URL_Image/Url_Image";
import { format, parseISO } from "date-fns";
import Loading from "../../../utils/Loading/Loading";

const ViewAluno = ({ Show, setShow, id_Aluno, setId_Aluno, id_Usuario, setId_Usuario, rm, setRm }) => {
  const [imageAluno, setImageAluno] = useState("");
  const [user, setUser] = useState(null);
  const [erro, setErro] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      if (isNaN(rm)) {
        setErro(`Erro ao obter informações do Aluno: ${rm}`);
        setLoading(false);
      } else if (id_Usuario) {
        try {
          const response = await api.get(`/usuario/buscar?id_usuario=${id_Usuario}`);
          setUser(response.data);
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      } else if (id_Aluno || (rm && !isNaN(rm))) {
        try {
          const response = await api.get(`/aluno/buscar?id_aluno=${id_Aluno}&rm=${rm}`);
          const data = response.data;
          setUser(data);
          setLoadingImg(true);
          setImageAluno(Url_Image(data.rm ? data.rm : rm));
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
          setLoadingImg(false);
        }
      }
    };

    fetchUserData();
  }, [id_Aluno, id_Usuario, rm]);

  const closeModal = () => {
    setShow(false);
    setImageAluno(null);
    setUser(null);
    setId_Usuario(null);
    setId_Aluno(null);
    setRm(null);
    setErro('');
  };

  if (!Show) return null;

  if (isNaN(rm)) {
    return (
      <Background Show={Show}>
        <Container>
          <Title>{erro}</Title>
          <DivMain>
            <Button
              type="button"
              width="50%"
              height="100%"
              placeholder="fechar"
              event={closeModal}
            />
          </DivMain>
        </Container>
      </Background>
    );
  }

  return (
    <Background Show={Show}>
      <Container>
        <Title>{id_Usuario ? 'Informações do Usuario' : 'Informações do Aluno'}</Title>
        <DivMain>
          {loading ? (
            <Loading />
          ) : (
            <>
              {id_Aluno || (rm && !isNaN(rm)) ? (
                <>
                  {loadingImg ? <Loading /> : <PreviewImage src={imageAluno} alt={user?.rm} />}
                  <DivContent>
                    <Header>RM:</Header><Content>{user?.rm}</Content>
                    <Header>NOME:</Header><Content>{user?.nome_aluno}</Content>
                    <Header>DATA NASCIMENTO:</Header><Content>{user?.dt_nascimento ? format(parseISO(user?.dt_nascimento), 'dd/MM/yyyy') : 'Sem data de nascimento'}</Content>
                    <Header>DATA INÍCIO:</Header><Content>{user?.dt_inicio ? format(parseISO(user?.dt_inicio), 'dd/MM/yyyy') : 'Sem data de Início'}</Content>
                    <Header>DATA TÉRMINO:</Header><Content>{user?.dt_termino ? format(parseISO(user?.dt_termino), 'dd/MM/yyyy') : 'Sem data de Término'}</Content>
                    <Header>CURSO:</Header><Content>{user?.nome_curso}</Content>
                    <Header>CONVÊNIO:</Header><Content>{user?.convenio}</Content>
                  </DivContent>
                </>
              ) : (
                <DivContent>
                  <Header>NOME:</Header><Content>{user?.nome}</Content>
                  <Header>E-MAIL:</Header><Content>{user?.email}</Content>
                </DivContent>
              )}
            </>
          )}
          <Button
            type="button"
            width="50%"
            height="100%"
            placeholder="fechar"
            event={closeModal}
          />
        </DivMain>
      </Container>
    </Background>
  );
};

export default ViewAluno;
