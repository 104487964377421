import React, { useEffect, useState } from "react"
import "./style.css"
import logo from "../../assets/images/logocarterinha.png"
import ass from "../../assets/images/ass.png"
import userDefault from "../../assets/images/user.png"
import api from "../../Services/api"
import axios from "axios"
import Url_Image from "../../assets/URL_Image/Url_Image"

export default function Carterinha() {
  const [alunoData, setAlunoData] = useState([])
  const [user, setUser] = useState("")
  const token = localStorage.getItem("token")

  const locale = "pt-BR"
  let imageUrl = ""

  useEffect(() => {
    async function getDataUser() {
      api
        .get("/aluno/perfil", token)
        .then(async (response) => {
          setAlunoData(response.data)
          imageUrl = Url_Image(response.data.rm)
          setUser(imageUrl)
          await axios
            .get(imageUrl)
            .then((response) => { })
            .catch((err) => {
              setUser("undefined")
              console.log(err)
            })
        })
        .catch((err) => {
          console.error(err)
        })
    }
    getDataUser()
  }, [])

  return (
    <div className="cardC">
      <div className="card__sideC card__side_frontC">
        <div className="flex__1C">
          <div className="roxoC" style={{ backgroundColor: alunoData.cor_convenio }} />
          <div className="fotoperfilstnC">
            <img src={user == "undefined" || !user ? userDefault : user} alt="foto" className="fotopefilsC" />
          </div>
          <div className="info-cardtC">
            <h1 className="nomeC">{alunoData.nome_aluno}</h1>
            <div className="descC">
              <div className="columnC">
                <p>RM</p>
                <p style={{ fontWeight: 800 }}>{alunoData.rm}</p>
              </div>
              <div className="columnC1">
                <p>Curso</p>
                <p className="nomeCursoC" >
                  {alunoData.nome_curso}
                </p>
              </div>
              <div className="columnC">
                <p>Validade</p>
                <p style={{ color: "red", fontWeight: "700" }}>
                  {new Date(alunoData.dt_termino).toLocaleDateString(locale)}
                </p>
              </div>
              <div className="logoC">
                <img className="fotoC" src={logo} alt="logo" />
              </div>
            </div>
          </div>
          <div className="cursotC" style={{ backgroundColor: alunoData.cor_convenio }}>
            <p className="turnotC">{alunoData.convenio}</p>
          </div>
        </div>
      </div>
      <div className="card__sideC card__side_backC">
        <div className="flex__2C">
          <div className="paragrafoC">
            <p className="paragrafoC2" >
              Este cartão é de uso estritamente pessoal e intransferível. O acesso a escola somente será permitido
              mediante apresentação desse cartão ou autorização da secretaria.
            </p>
          </div>
          <div className="infobackC">
            <h1 className="titleC">Prefeitura Municipal De Indaiatuba</h1>
            <img className="assC" src={ass} alt="ass" />
          </div>
        </div>
      </div>
    </div>
  )
}
