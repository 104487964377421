import React, { useEffect, useState, useContext } from "react"
import {
  ButtonNew,
  ButtonSeach,
  Buttons,
  Card,
  Contanier,
  Forms,
  Gruop,
  Header,
  Image,
  Title,
  paragrafo,
} from "./style"
import Icone from "../../../utils/Icone"
import Url_Image from "../../../assets/URL_Image/Url_Image"

export default function ViewPicture({ viewPicture, setViewPicture }) {
  var imageUrl = ""
  const [User, setUser] = useState("")
  useEffect(() => {
    async function takePhoto() {
      try {
        var rm = localStorage.getItem("rm")
        imageUrl = Url_Image(rm)
        const response = await fetch(imageUrl)
        if (!response.ok) {
          setUser("undefined")
        }
        const blob = await response.blob()
        const url = await URL.createObjectURL(blob)

        setUser(url)
      } catch (error) {
        setUser("undefined")
        console.error(error)
      }
    }
    takePhoto()
  }, [viewPicture])

  if (viewPicture == true) {
    return (
      <Contanier>
        <Card>
          <Header>
            <Buttons
              onClick={() => {
                setViewPicture(false)
              }}
            >
              <Icone name={"X"} color={" #3a3f86"} size={50} />
            </Buttons>
          </Header>
          <Image src={User} alt="logo" />
        </Card>
      </Contanier>
    )
  } else if (setViewPicture == false) {
    return null
  }
}
