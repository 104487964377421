import React, { useEffect, useState } from "react";
import "./style.css"
import { Container, ContainerTable, Table, Theader, Trbody, Td, Th, Grup, Tbody, TdUpload, Buttons, Menus, Title, ModalTest, PreviewImage } from "./style"
import { useNavigate } from "react-router-dom"
import UserDefault from "../../assets/images/user.png"
import Pesquisar from "../../Components/Pesquisar"
import api from "../../Services/api"
import EditAluno from "../../Components/modals/EditAluno"
import { Alert } from "../../utils/Alert"
import { format } from "date-fns"
import Button from "../../Components/Button"
import Url_Image from "../../assets/URL_Image/Url_Image";

export default function AlunoSemFoto() {
  const Navigate = useNavigate()
  const [Show, setShow] = useState(false)
  const [cursos, setCursos] = useState([])
  const [initalAlunos, setInitalAlunos] = useState([])
  const [alunos, setAlunos] = useState([])
  const [editAluno, setEditAluno] = useState([])
  const [searchRm, setSearchRm] = useState("")
  const [searchNome, setSearchNome] = useState("")
  const [searchDataNascimento, setSearchDataNascimento] = useState("")
  const [searchDataInicio, setSearchDataInicio] = useState("")
  const [searchDataFim, setSearchDataFim] = useState("")
  const [searchCurso, setSearchCurso] = useState("")
  const [searchConvenio, setSearchConvenio] = useState("")
  const [Open, setOpen] = useState(true)
  const [mostrarTodos, setMostrarTodos] = useState(300);
  const limite = 0.5

  useEffect(() => {
    async function getallalunos() {
      api.get("/aluno/alunosemfoto?procurar").then(async (response) => {
        if (response.data.mensagem == "Nenhum aluno encontrado.") {
          setAlunos([])
        } else {
          setInitalAlunos(response.data)
          const data = await response.data
          setAlunos(data)
          // setMostrarTodos(data.length * limite)
          setMostrarTodos(data.length)
        }
      })
      await api
        .get("/aluno/alunosemfoto?procurar")
        .then((res) => {
          const data = res.data
          const resultsCurso = []
          data.forEach((value) => {
            resultsCurso.push({
              label: value.nome,
              value: value.id,
            })
          })
          setCursos(resultsCurso)
        })
        .catch((err) => {
          console.error(err)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    getallalunos()
  }, [])

  useEffect(() => {
    if (searchRm.length > 0 || searchNome.length > 0 || searchDataNascimento.length > 0 || searchDataInicio.length > 0 ||
      searchDataFim.length > 0 || searchCurso.length > 0 || searchConvenio.length > 0) {
      setMostrarTodos(10000)
    } else {
      setMostrarTodos(filteredAlunos.length * limite)
    }
  }, [searchRm, searchNome, searchDataNascimento, searchDataInicio, searchDataFim, searchCurso, searchConvenio]);

  async function takePhoto(rm) {
    try {
      localStorage.setItem("rm", rm)
      setTimeout(() => {
        setViewPicture(true)
      }, 600)
    } catch (err) {
      console.log(err)
    }
  }

  const normalizeString = (str) => {
    if (str === undefined || str === null) {
      return "";
    }
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const filteredAlunos = alunos.filter((aluno) => {
    const searchRMNormalized = normalizeString(searchRm);
    const searchNomeNormalized = normalizeString(searchNome);
    const searchCursoNormalized = normalizeString(searchCurso);
    const searchConvenioNormalized = normalizeString(searchConvenio);
    const searchDataNascimentoNormalized = normalizeString(searchDataNascimento);
    const searchDataInicioNormalized = normalizeString(searchDataInicio);
    const searchDataFimNormalized = normalizeString(searchDataFim);

    const alunoRM = normalizeString(aluno.rm?.toString() || "");
    const alunoNome = normalizeString(aluno.nome_aluno || "");
    const alunoCurso = normalizeString(aluno.nome_curso || "");
    const alunoConvenio = normalizeString(aluno.convenio || "");
    const alunoDataNascimento = normalizeString(aluno.dt_nascimento ? format(new Date(aluno.dt_nascimento), "dd/MM/yyyy") : "");
    const alunoDataInicio = normalizeString(aluno.dt_inicio ? format(new Date(aluno.dt_inicio), "dd/MM/yyyy") : "");
    const alunoDataFim = normalizeString(aluno.dt_termino ? format(new Date(aluno.dt_termino), "dd/MM/yyyy") : "");

    return (
      alunoRM.includes(searchRMNormalized) &&
      alunoNome.includes(searchNomeNormalized) &&
      alunoCurso.includes(searchCursoNormalized) &&
      alunoConvenio.includes(searchConvenioNormalized) &&
      alunoDataNascimento.includes(searchDataNascimentoNormalized) &&
      alunoDataInicio.includes(searchDataInicioNormalized) &&
      alunoDataFim.includes(searchDataFimNormalized)
    );
  }).slice(0, mostrarTodos);

  return (
    <Container>
      <Menus>
        <Title>Alunos sem Fotos</Title>
        <Buttons>
          <Button
            event={() => {
              Navigate("/visualizaralunos")
            }}
            width="12%"
            margintop="2%"
            marginleft="1%"
            backgroundcolor="#1a2e35"
            placeholder="Voltar"
            borderRadius="30px 30px 0px 0px"
          />
        </Buttons>
          <Title style={{marginRight:"1%"}}>{filteredAlunos.length} Alunos</Title>
      </Menus>
      <ContainerTable>
        <Table>
            <Theader>
              <tr>
                <Th>
                  <Grup>
                    <Pesquisar
                      color="white"
                      placeholder="RM"
                      name="rm"
                      placeholdercolor="white"
                      event={(e) => { setSearchRm(e.target.value) }}
                    />
                  </Grup>
                </Th>
                <Th>
                  <Grup>
                    <Pesquisar
                      placeholder="Nome"
                      name="nome"
                      event={(e) => { setSearchNome(e.target.value) }}
                    />
                  </Grup>
                </Th>
                <Th>
                  <Grup>
                    <Pesquisar
                      placeholder="Nascimento"
                      name="data_nascimento"
                      event={(e) => { setSearchDataNascimento(e.target.value) }}
                    />
                  </Grup>
                </Th>
                <Th>
                  <Grup>
                    <Pesquisar
                      placeholder="Data Início"
                      name="data_inicio"
                      event={(e) => { setSearchDataInicio(e.target.value) }}
                    />
                  </Grup>
                </Th>
                <Th>
                  <Grup>
                    <Pesquisar
                      placeholder="Data Fim"
                      name="data_fim"
                      event={(e) => { setSearchDataFim(e.target.value) }}
                    />
                  </Grup>
                </Th>
                <Th>
                  <Grup>
                    <Pesquisar
                      placeholder="Curso"
                      name="nome_curso"
                      event={(e) => { setSearchCurso(e.target.value) }}
                    />
                  </Grup>
                </Th>
                <Th>
                  <Grup>
                    <Pesquisar
                      placeholder="Convênio"
                      name="convenio"
                      event={(e) => { setSearchConvenio(e.target.value) }}
                    />
                  </Grup>
                </Th>
              </tr>
            </Theader>
          <Tbody>
            {filteredAlunos?.map((d, i) => (
              <Trbody key={i}>
                {/* d?.foto == null ?*/}
                {d?.ativo == 0 ? (
                  <>
                    <TdUpload style={{ color: "red" }}>
                      {d?.rm}{" "}
                      <PreviewImage

                        src={Url_Image(d?.rm)}
                      // onClick={() => {
                      //   takePhoto(d?.rm)
                      // }}
                      />
                    </TdUpload>
                    <Td style={{ color: "red" }}>{d?.nome_aluno}</Td>
                    <Td style={{ color: "red" }}>{format(new Date(d?.dt_nascimento), "dd/MM/yyyy")}</Td>
                    <Td style={{ color: "red" }}>{format(new Date(d?.dt_inicio), "dd/MM/yyyy")}</Td>
                    <Td style={{ color: "red" }}>{format(new Date(d?.dt_termino), "dd/MM/yyyy")}</Td>
                    <Td style={{ color: "red" }}>{d?.nome_curso}</Td>
                    <Td style={{ color: "red" }}>{d?.convenio}</Td>
                  </>
                ) : (
                  <>
                    <TdUpload>
                      {d?.rm}{" "}
                      <PreviewImage
                        src={Url_Image(d?.rm)}
                      // onClick={() => {
                      //   takePhoto(d?.rm)
                      // }}
                      />
                    </TdUpload>
                    <Td>{d?.nome_aluno}</Td>
                    <Td>{format(new Date(d?.dt_nascimento), "dd/MM/yyyy")}</Td>
                    <Td>{format(new Date(d?.dt_inicio), "dd/MM/yyyy")}</Td>
                    <Td>{format(new Date(d?.dt_termino), "dd/MM/yyyy")}</Td>
                    <Td>{d?.nome_curso}</Td>
                    <Td>{d?.convenio}</Td>
                  </>
                )}
              </Trbody>
            ))}
          </Tbody>
        </Table>
      </ContainerTable>
      <EditAluno Show={Show} setShow={setShow} setEditAluno={setEditAluno} editAluno={editAluno} cursos={cursos} />
      {/* <ModalTest>
        {alunos?.map((d, i) => (<h7 key={i}>{<p style={{border: '1px solid black', margin:'2%'}}>{d?.rm}, <PreviewImage src={ !`${Url_Imagem.STATIC_URL_IMAGE}btoa(d?.rm)` ? UserDefault : `${Url_Imagem.STATIC_URL_IMAGE}${btoa(d?.rm)}` } /></p>}</h7>))}<br/>
      </ModalTest> */}
    </Container>
  )
}
