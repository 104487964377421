import React from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import Login from "./Pages/Login"
import HomeAluno from "./Pages/HomeAluno"
import HomeSecretaria from "./Pages/HomeSecretaria"
import Navbar from "./Components/Navbar"
import Globalstyles from "./globalstyles"
import { Authenticator, AuthenticatorAdmin } from "./auth"
import NaoAutorizado from "./Pages/NaoAutorizado"
import CadastroAluno from "./Pages/CadastroAluno"
import DesativarAluno from "./Pages/DesativarAluno"
import GerarCarteirinhas from "./Pages/GerarCarteirinhas"
import VisualizarAlunos from "./Pages/VisualizarAlunos"
import VerCarterinhas from "./Pages/VerCarterinhas"
import VisualizarUsuarios from "./Pages/VisualizarUsuarios"
import { useEffect, useState } from "react"
import AlunoSemFoto from "./Pages/AlunoSemFoto"
import VerCarterinha from "./Pages/VerCarterinha"
import DadosPublicos from "./Pages/DadosPublicos/index.jsx"
import CarteirinhaPdfS from "./Components/Pdf/carteirinhaSecreHori"
import CarteirinhaVertiPdf from "./Components/Pdf/CarteirinhaVertiPdf"
import Pdf from "./Components/Pdf"

function App() {
  function AuthRoute({ children }) {
    return Authenticator() ? children : <NaoAutorizado />
  }

  function AuthAdmRoute({ children }) {
    return AuthenticatorAdmin() ? children : <NaoAutorizado />
  }

  const [firstAccess, setFirstAccess] = useState(Boolean)

  useEffect(() => {
    if (!!localStorage.getItem("Entrada") == true) {
      // console.log("existe")
      if (localStorage.getItem("Entrada") == "Informe uma senha para o aluno.") {
        setFirstAccess(true)
      } else {
        setFirstAccess(false)
      }
    } else {
    }
  }, [])

  const acesso = {
    sinal: firstAccess,
  }
  // setTimeout(()=> console.clear() ,2500)
  

  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path={"*"} element={<Login />} />
        <Route path={"/"} element={<Login />} />
        <Route
          path={"/aluno"}
          element={
            <AuthRoute>
              <HomeAluno first={acesso.sinal} />
            </AuthRoute>
          }
        />
        <Route
          path={"/secretaria"}
          element={
            <AuthAdmRoute>
              <HomeSecretaria />
            </AuthAdmRoute>
          }
        />
         <Route
          path={"/vercarterinhasecretaria"}
          element={
            <AuthAdmRoute>
              <VerCarterinha />
            </AuthAdmRoute>
          }
        />
        <Route
          path={"/cadastroaluno"}
          element={
            <AuthAdmRoute>
              <CadastroAluno />
            </AuthAdmRoute>
          }
        />
        <Route
          path={"/desativaraluno"}
          element={
            <AuthAdmRoute>
              <DesativarAluno />
            </AuthAdmRoute>
          }
        />
        <Route
          path={"/vercarterinhas"}
          element={
            <AuthAdmRoute>
              <VerCarterinhas />
            </AuthAdmRoute>
          }
        />
        <Route
          path={"/gerarcarteirinhas"}
          element={
            <AuthAdmRoute>
              <GerarCarteirinhas />
            </AuthAdmRoute>
          }
        />
        <Route
          exact
          path="/Ajuda"
          element={() => {
            window.location.href = "https://alunos.tawk.help"
          }}
        />
        <Route
          path={"/visualizaralunos"}
          element={
            <AuthAdmRoute>
              <VisualizarAlunos />
            </AuthAdmRoute>
          }
        />
        <Route
          path={"/visualizarusuarios"}
          element={
            <AuthAdmRoute>
              <VisualizarUsuarios />
            </AuthAdmRoute>
          }
        />
        <Route
          path={"/alunosemfoto"}
          element={
            <AuthAdmRoute>
              <AlunoSemFoto />
            </AuthAdmRoute>
          }
        />
        {/* <Route
          path={"/alunosdesativados"}
          element={
            <AuthAdmRoute>
              <AlunoSemFoto />
            </AuthAdmRoute>
          }
        /> */}
         <Route
             path={"/v/:id"}
             element={
              <DadosPublicos />
             }
           />
      </Routes>
      <Globalstyles />
    </BrowserRouter>
  )
}

export default App
