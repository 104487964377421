import React, { useEffect, useState } from "react"
import "./stylePdf.css"
import logo from "../../assets/images/logocarterinha.png"
import userDefault from "../../assets/images/user.png"
import ass from "../../assets/images/ass.png"
import api from "../../Services/api"
import Url_Image from "../../assets/URL_Image/Url_Image"

export default function CarteirinhaHoriPdf() {
  const locale = "pt-BR"
  let imageUrl = ""

  const [user, setUser] = useState("")
  const [alunoData, setAlunoData] = useState([])
  useEffect(() => {
    const token = localStorage.getItem("token")
    async function getDataUser() {
      api
        .get("/aluno/perfil", token)
        .then(async (response) => {
          setAlunoData(response.data)
          imageUrl = Url_Image(response.data.rm)

          try {
            const response = await fetch(imageUrl)
            if (!response.ok) {
              setUser("undefined")
            }
            const blob = await response.blob()
            const url = await URL.createObjectURL(blob)
            setUser(url)
          } catch (error) {
            setUser("undefined")
            console.error(error)
          }
        })
        .catch((err) => {
          setUser("undefined")
          console.log(err)
        })
    }
    getDataUser()
  }, [])
  return (
    <div className="main">
      <div className="cardpdf">
        <div className="flex__3">
          <div className="roxopdf" style={{ backgroundColor: alunoData.cor_convenio }} />
          <div className="perfilpdf">
            <img src={user == "undefined" || !user ? userDefault : user} alt="foto" className="perfilphotospdf" />
          </div>
          <div className="info-cardpdf">
            <h1 className="nomepdf">{alunoData.nome_aluno}</h1>
            <div className="descpdf">
              <div className="column">
                <p>RM</p>
                <p style={{ fontWeight: 800 }}>{alunoData.rm}</p>
              </div>
              <div className="column1">
                <p>Curso</p>
                <p style={{ textTransform: "uppercase", fontSize: 12, fontWeight: "700", whiteSpace: "nowrap" }}>
                  {alunoData.nome_curso}
                </p>
              </div>
              <div className="column">
                <p>Validade</p>
                <p style={{ color: "red", fontWeight: "700" }}>
                  {" "}
                  {new Date(alunoData.dt_termino).toLocaleDateString(locale)}
                </p>
              </div>
              <div className="logopdf">
                <img className="fotopdf" src={logo} alt="logo" />
              </div>
            </div>
          </div>
          <div className="cursopdf" style={{ backgroundColor: alunoData.cor_convenio }}>
            <p className="turnuss">{alunoData.convenio}</p>
          </div>
        </div>
        <div className="flex__4">
          <div className="paragrafopdf">
            <p style={{ marginLeft: 20 }}>
              Este cartão é de uso estritamente pessoal e intransferível.O acesso a escola somente será permitido
              mediante apresentação desse cartão ou autorização da secretaria.
            </p>
          </div>
          <div className="infobackpdf" >
            <h1 className="title">Prefeitura Municipal De Indaiatuba</h1>
            <img className="asspdf" src={ass} alt="ass" />
          </div>
        </div>
      </div>
    </div>
  )
}
