import React, { useEffect, useState } from "react"
import "./style.css"
import {
  Container, ContainerTable, Menus, Table, Theader, Trbody, Td, Th, Title, Buttons, Colletion, Grup,
  Tbody, EditButton, DeleteButton, DesativarButton, AtivarButton, UploadPhto, TdUpload, PreviewImage, TdUploadAtivo,
  TdAtivo, ThRm,
} from "./style"
import Button from "../../Components/Button"
import UserDefault from "../../assets/images/user.png"
import { useNavigate } from "react-router-dom"
import Pesquisar from "../../Components/Pesquisar"
import api from "../../Services/api"
import EditAluno from "../../Components/modals/EditAluno"
import { Alert } from "../../utils/Alert"
import ViewPicture from "../../Components/modals/ViewPictiure"
import * as XLSX from "xlsx"
import { format } from "date-fns"
import { Database } from "lucide-react"
import Url_Image from "../../assets/URL_Image/Url_Image"

export default function VisualizarAlunos() {
  const Navigate = useNavigate()
  const [Show, setShow] = useState(false)
  const [cursos, setCursos] = useState([])
  const [viewPicture, setViewPicture] = useState(false)
  const [alunos, setAlunos] = useState([])
  const [alunosBk, setAlunosBk] = useState([])
  const [exibirInativos, setExibirInativos] = useState(false);
  const [editAluno, setEditAluno] = useState([])
  const [TrocaTextoInativos, setTrocaTextoInativos] = useState("")
  const [searchRm, setSearchRm] = useState("")
  const [searchNome, setSearchNome] = useState("")
  const [searchDataNascimento, setSearchDataNascimento] = useState("")
  const [searchDataInicio, setSearchDataInicio] = useState("")
  const [searchDataFim, setSearchDataFim] = useState("")
  const [searchCurso, setSearchCurso] = useState("")
  const [searchConvenio, setSearchConvenio] = useState("")
  const [mostrarTodos, setMostrarTodos] = useState(300);
  const limite = 0.30  // 30%

  async function getallalunos() {
    api.get("/aluno/listar?procurar").then(async (response) => {
      if (response.data.mensagem == "Nenhum aluno encontrado.") {
        setAlunos([])
        setAlunosBk([])
      } else {
        const data = await response.data
        setAlunos(data)
        setAlunosBk(data)
        setMostrarTodos(data.length * limite)
      }
    })
    await api
      .get("/curso/listar?procurar")
      .then((res) => {
        const data = res.data
        const resultsCurso = []
        data.forEach((value) => {
          resultsCurso.push({
            label: value.nome,
            value: value.id,
          })
        })
        setCursos(resultsCurso)
      })
      .catch((err) => {
        console.error(err)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  useEffect(() => { getallalunos() }, [])
  useEffect(() => {
    if (exibirInativos) {
      setTrocaTextoInativos("Mostrar Todos")
      setAlunos(alunosBk.filter(a => a.ativo != 1));
    } else {
      setTrocaTextoInativos("Mostrar Inativos")
      setAlunos(alunosBk.filter(a => a.ativo != 'qualquer coisa'));
    }
  }, [exibirInativos]);

  // useEffect(() => {
  //   if (searchRm.length > 1 || searchNome.length > 1 || searchDataNascimento.length > 1 || searchDataInicio.length > 1 ||
  //     searchDataFim.length > 1 || searchCurso.length > 1 || searchConvenio.length > 1) {
  //     setMostrarTodos(alunos.length)
  //   } else {
  //     setMostrarTodos(alunos.length * limite)
  //   }
  // }, [searchRm, searchNome, searchDataNascimento, searchDataInicio, searchDataFim, searchCurso, searchConvenio]);

  async function takePhoto(rm) {
    try {
      localStorage.setItem("rm", rm)
      setTimeout(() => {
        setViewPicture(true)
      }, 600)
    } catch (err) {
      console.log(err)
    }
  }

  const normalizeString = (str) => {
    if (str === undefined || str === null) {
      return "";
    }
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };
  
  const filteredAlunos = alunos.filter((aluno) => {
    const searchRMNormalized = normalizeString(searchRm);
    const searchNomeNormalized = normalizeString(searchNome);
    const searchCursoNormalized = normalizeString(searchCurso);
    const searchConvenioNormalized = normalizeString(searchConvenio);
    const searchDataNascimentoNormalized = normalizeString(searchDataNascimento);
    const searchDataInicioNormalized = normalizeString(searchDataInicio);
    const searchDataFimNormalized = normalizeString(searchDataFim);

    const alunoRM = normalizeString(aluno.rm?.toString() || "");
    const alunoNome = normalizeString(aluno.nome_aluno || "");
    const alunoCurso = normalizeString(aluno.nome_curso || "");
    const alunoConvenio = normalizeString(aluno.convenio || "");
    const alunoDataNascimento = normalizeString(aluno.dt_nascimento ? format(new Date(aluno.dt_nascimento), "dd/MM/yyyy") : "");
    const alunoDataInicio = normalizeString(aluno.dt_inicio ? format(new Date(aluno.dt_inicio), "dd/MM/yyyy") : "");
    const alunoDataFim = normalizeString(aluno.dt_termino ? format(new Date(aluno.dt_termino), "dd/MM/yyyy") : "");

    return (
      alunoRM.includes(searchRMNormalized) &&
      alunoNome.includes(searchNomeNormalized) &&
      alunoCurso.includes(searchCursoNormalized) &&
      alunoConvenio.includes(searchConvenioNormalized) &&
      alunoDataNascimento.includes(searchDataNascimentoNormalized) &&
      alunoDataInicio.includes(searchDataInicioNormalized) &&
      alunoDataFim.includes(searchDataFimNormalized)
    );
  }).slice(0, mostrarTodos);

  const formatarDados = (dados) => {
    return dados.map((aluno) => ({
      RM: aluno.rm,
      NOME: aluno.nome_aluno,
      EMAIL: aluno.email,
      "DATA NASCIMENTO": format(new Date(aluno.dt_nascimento), "dd/MM/yyyy"),
      CURSO: aluno.nome_curso,
      CONVÊNIO: aluno.convenio,
      "DATA DE INÍCIO": format(new Date(aluno.dt_inicio), "dd/MM/yyyy"),
      "DATA DE TÉRMINO": format(new Date(aluno.dt_termino), "dd/MM/yyyy"),
    }))
  }

  const handleDownloadXLSX = () => {
    if (filteredAlunos) {
      const dadosFormatados = formatarDados(filteredAlunos)
      const ws = XLSX.utils.json_to_sheet(dadosFormatados)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1")
      XLSX.writeFile(wb, "alunos.xlsx")
    } else {
      console.log("algo deu errado")
    }
  }

  async function desativarAluno(rm, id_aluno) {
    try {
      Alert("Alert", `Deseja desativar  o aluno  ${rm}`).then((result) => {
        if (result.isConfirmed) {
          api
            .put(`/aluno/atualizar-status?id_aluno=${id_aluno}&rm=${rm}`)
            .then(() => { window.location.reload() })
            .catch((err) => {
              console.log(err)
              Alert("Error", "Ocorreu um erro ao desativar aluno")
            })
        }
      })
    } catch (err) {
      console.log(err)
      Alert("Error", "Ocorreu um erro ao desativar aluno")
    }
  }
  async function deletarAluno(id_aluno, nome, rm) {
    try {
      Alert("Alert", `Deseja deletar  o aluno  ${rm} `).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(`/aluno/apagar?id_aluno=${id_aluno}&rm=${rm}`)
            .then(() => window.location.reload())
            .catch((err) => {
              console.log(err)
              Alert("Error", "Ocorreu um erro ao desativar aluno")
            })
        }
      })
    } catch (err) {
      console.log(err)
      Alert("Error", "Ocorreu um erro ao desativar aluno")
    }
  }

  async function ativarAluno(rm, idAluno) {
    try {
      Alert("Alert", `Deseja ativar o aluno  ${rm} `).then((result) => {
        if (result.isConfirmed) {
          api
            .put(`/aluno/atualizar-status?id_aluno=${idAluno}&rm=${rm}`)
            .then(() => { window.location.reload() })
            .catch((err) => {
              console.log(err)
              Alert("Error", "Ocorreu um erro ao desativar aluno")
            })
        }
      })
    } catch (err) {
      console.log(err)
      Alert("Error", "Ocorreu um erro ao desativar aluno")
    }
  }
  async function verImagem(rm) {
    try {
      const image = Url_Image(rm)
      // console.log(image)
      const response = await fetch(image)
      // console.log(response)
      if (!response.ok) {
        return UserDefault
      }
      const blob = await response.blob()
      const url = URL.createObjectURL(blob)
      return url
    } catch (err) {
      console.log(err)
      return UserDefault
    }
  }

  return (
    <>
      <ViewPicture viewPicture={viewPicture} setViewPicture={setViewPicture} />
      <Container>
        <Menus>
          <Title>Controle de Alunos</Title>
          <Buttons>
            <Button
              event={() => {
                Navigate("/secretaria")
              }}
              width="12%"
              margintop="2%"
              placeholder="Voltar"
              backgroundcolor="#1a2e35"
              borderRadius="30px 30px 0px 0px"
            />
            <Button
              event={() => {
                Navigate("/cadastroaluno")
              }}
              width="12%"
              margintop="2%"
              backgroundcolor="#1a2e35"
              placeholder="Adicionar"
              borderRadius="30px 30px 0px 0px"
            />
            <Button
              event={() => {
                handleDownloadXLSX()
              }}
              width="12%"
              margintop="2%"
              backgroundcolor="#1a2e35"
              placeholder="Download"
              borderRadius="30px 30px 0px 0px"
            />
            <Button
              event={() => {
                Navigate("/alunosemfoto")
              }}
              width="14%"
              margintop="2%"
              backgroundcolor="#1a2e35"
              placeholder="Alunos Sem Foto"
              borderRadius="30px 30px 0px 0px"
            />
            <Button
              event={() => { setExibirInativos(!exibirInativos) }}
              width="12%"
              margintop="2%"
              backgroundcolor="#1a2e35"
              placeholder={TrocaTextoInativos}
              borderRadius="30px 30px 0px 0px"
            />
            {exibirInativos || searchRm.length || searchNome.length || searchDataNascimento.length || searchDataInicio.length ||
              searchDataFim.length || searchCurso.length || searchConvenio.length ? null :
              (
                <>
                  {mostrarTodos < alunos.length ? (
                    <Button
                      event={() => setMostrarTodos(alunos.length)}
                      width="12%"
                      margintop="2%"
                      backgroundcolor="#1a2e35"
                      placeholder="Mostrar Todos"
                      borderRadius="30px 30px 0px 0px"
                    />
                  ) : (
                    <Button
                      event={() => setMostrarTodos(alunos.length * limite)}
                      width="12%"
                      margintop="2%"
                      backgroundcolor="#1a2e35"
                      placeholder="Reduzir Alunos"
                      borderRadius="30px 30px 0px 0px"
                    />
                  )}
                </>
              )}
          </Buttons>
          {/* <Colletion>
            <CheckBox placeholder="Desativar Todos" marginTop={24} />
          </Colletion> */}
        </Menus>
        <ContainerTable>
          <Table>
            <Theader>
              <tr>
                <ThRm>
                  <Grup>
                    <Pesquisar
                      color="white"
                      placeholder="RM"
                      name="rm"
                      placeholdercolor="white"
                      event={(e) => { setSearchRm(e.target.value) }}
                    />
                  </Grup>
                </ThRm>
                <Th>
                  <Grup>
                    <Pesquisar
                      placeholder="Nome"
                      name="nome"
                      event={(e) => { setSearchNome(e.target.value) }}
                    />
                  </Grup>
                </Th>
                <Th>
                  <Grup>
                    <Pesquisar
                      placeholder="Nascimento"
                      name="data_nascimento"
                      event={(e) => { setSearchDataNascimento(e.target.value) }}
                    />
                  </Grup>
                </Th>
                <Th>
                  <Grup>
                    <Pesquisar
                      placeholder="Data Início"
                      name="data_inicio"
                      event={(e) => { setSearchDataInicio(e.target.value) }}
                    />
                  </Grup>
                </Th>
                <Th>
                  <Grup>
                    <Pesquisar
                      placeholder="Data Fim"
                      name="data_fim"
                      event={(e) => { setSearchDataFim(e.target.value) }}
                    />
                  </Grup>
                </Th>
                <Th>
                  <Grup>
                    <Pesquisar
                      placeholder="Curso"
                      name="nome_curso"
                      event={(e) => { setSearchCurso(e.target.value) }}
                    />
                  </Grup>
                </Th>
                <Th>
                  <Grup>
                    <Pesquisar
                      placeholder="Convênio"
                      name="convenio"
                      event={(e) => { setSearchConvenio(e.target.value) }}
                    />
                  </Grup>
                </Th>
                <Th></Th>
                <Th></Th>
                <th>{filteredAlunos.length} Alunos</th>
              </tr>
            </Theader>
            <Tbody>
              {filteredAlunos?.map((d, i) => (
                <Trbody key={i}>
                  {d?.ativo == 0 ? (
                    <>
                      <TdUpload style={{ color: "red" }}>
                        {d?.rm}{" "}
                        <PreviewImage
                          src={Url_Image(d?.rm)}
                          onClick={() => {
                            takePhoto(d?.rm)
                          }}
                        />
                      </TdUpload>
                      <Td style={{ color: "red" }}>{d?.nome_aluno}</Td>
                      <Td style={{ color: "red" }}>{format(new Date(d?.dt_nascimento), "dd/MM/yyyy")}</Td>
                      <Td style={{ color: "red" }}>{format(new Date(d?.dt_inicio), "dd/MM/yyyy")}</Td>
                      <Td style={{ color: "red" }}>{format(new Date(d?.dt_termino), "dd/MM/yyyy")}</Td>
                      <Td style={{ color: "red" }}>{d?.nome_curso}</Td>
                      <Td style={{ color: "red" }}>{d?.convenio}</Td>
                      <Td>
                        <EditButton
                          style={{ color: "red" }}
                          title="Editar Aluno"
                          onClick={() => {
                            setShow(true)
                            setEditAluno(d)
                          }}
                        />
                      </Td>
                      <Td style={{ color: "red" }}>
                        <AtivarButton
                          title="Ativar Aluno"
                          onClick={() => {
                            ativarAluno(d?.rm, d?.id)
                          }}
                        />
                      </Td>
                      <Td>
                        <DeleteButton
                          style={{ color: "red" }}
                          title="Deletar Aluno"
                          onClick={() => {
                            deletarAluno(d?.id, d?.nome, d?.rm)
                          }}
                        />
                      </Td>
                    </>
                  ) : (
                    <>
                      <TdUploadAtivo >
                        {d?.rm}{" "}
                        <PreviewImage
                          src={Url_Image(d?.rm)}
                          onClick={() => {
                            takePhoto(d?.rm)
                          }}
                        />
                      </TdUploadAtivo>
                      <TdAtivo>{d?.nome_aluno}</TdAtivo>
                      <TdAtivo>{format(new Date(d?.dt_nascimento), "dd/MM/yyyy")}</TdAtivo>
                      <TdAtivo>{format(new Date(d?.dt_inicio), "dd/MM/yyyy")}</TdAtivo>
                      <TdAtivo>{format(new Date(d?.dt_termino), "dd/MM/yyyy")}</TdAtivo>
                      <TdAtivo>{d?.nome_curso}</TdAtivo>
                      <TdAtivo>{d?.convenio}</TdAtivo>
                      <TdAtivo>
                        <EditButton
                          title="Editar Aluno"
                          onClick={() => {
                            setEditAluno(d)
                            setShow(true)
                          }}
                        />
                      </TdAtivo>
                      <TdAtivo>
                        <DesativarButton
                          title="Desativar Aluno"
                          onClick={() => {
                            desativarAluno(d?.rm, d?.id)
                          }}
                        />
                      </TdAtivo>
                      <TdAtivo>
                        <DeleteButton
                          title="Deletar Aluno"
                          onClick={() => {
                            deletarAluno(d?.id, d?.nome, d?.rm)
                          }}
                        />
                      </TdAtivo>
                    </>
                  )}
                </Trbody>
              ))}
            </Tbody>
          </Table>
        </ContainerTable>
        <EditAluno Show={Show} setShow={setShow} setEditAluno={setEditAluno} editAluno={editAluno} cursos={cursos} />
      </Container>
    </>
  )
}