import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  height: 87vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    /* Esconder a scrollbar no navegador Chrome, Safari e Edge */
    ::-webkit-scrollbar {
    display: none;
  }

  /* Esconder a scrollbar no Firefox */
  scrollbar-width: none;

  /* Prevenir problemas de layout ao esconder a scrollbar */
  -ms-overflow-style: none;  /* IE 11 */
  
`

export const CardBox = styled.div`
  width: 10vw;
  height: 10vh;
  background-color: black;
`

export const CartDiv = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Buttons = styled.div`
  height: 100%;
  width: 15%;
  min-width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5vw;
`
export const ContainerMenu = styled.div`
  width: 100%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
    /* Esconder a scrollbar no navegador Chrome, Safari e Edge */
    ::-webkit-scrollbar {
    display: none;
  }

  /* Esconder a scrollbar no Firefox */
  scrollbar-width: none;

  /* Prevenir problemas de layout ao esconder a scrollbar */
  -ms-overflow-style: none;  /* IE 11 */
  

  @media(max-width: 1100px) {
    padding-bottom: 10%;
  }
  @media(max-width: 600px) {
    padding-bottom: 25%;
  }
  @media(max-height: 700px) {
    height: 90vh;
    padding-bottom: 3rem;
  }
  
`

export const Menu = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: right;
  margin-right: 15%;
  margin-top: 0%;
  z-index: 1;

  @media(max-width: 430px){
    height: 20%;
  }
`

export const MainDiv = styled.div`
  width: 100%;
  gap: 11%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 1200px) {
    gap: 5%;
  }
`

export const ButtonChange = styled.div`
  width: 100%;
  height: 4%;
  display: flex;
  justify-content: center;
  margin-top: -2%;
`

export const ButtonRender = styled.div`
  width: 10%;
  margin-left: 1%;
  height: 90%;
  display: flex;
  align-items: center;

  @media(max-width: 1210px) {
    width: 23%;
    height: 90%;
    display: flex;
    align-items: center;
  }
`
export const Render = styled.div`
  display: flex;
  margin-top: 6%;
  height: 100%;
  justify-content: center;
  width: 20%;
`
export const Main = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 1200px) {
    justify-content: space-around;
    gap: 5%;
  }
`
export const divPrint = styled.div`
  width: 30%;
`
export const DivImagem = styled.div`
  height: 90%;
  gap: 1rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`