import React, { useState } from "react"
import { Buttons, Card, Contanier, Forms, Header, Title } from "./style"
import Input from "../../Input"
import Button from "../../Button"
import { Alert } from "../../../utils/Alert"
import Icone from "../../../utils/Icone"
import api from "../../../Services/api"
import Swal from "sweetalert2"

export default function CriarUsario({ isActived, setIsActived }) {
  const [Nome, setNome] = useState("")
  const [Email, setEmail] = useState("")
  const [Senha, setSenha] = useState("")
  const token = localStorage.getItem("token")
  const data = {
    email: Email,
    nome: Nome,
    senha: Senha,
  }
  function setAllFalse() {
    setNome("")
    setEmail("")
    setSenha("")
  }

  function HandleManualSubmit(e) {
    if (data.email.includes("@") === true) {
      api
        .post("/usuario/cadastrar", { data })
        .then((res) => {
          Swal.fire({
            title: "Concluido",
            text: `${res.data.mensagem}`,
            icon: "success",
            iconColor: "green",
            confirmButtonColor: "var(--azul)",
            confirmButtonText: "Confirmar",
          })
          setAllFalse()
          setIsActived(false)
          window.location.reload()
        })
        .catch((err) => {
          Swal.fire({
            title: "Erro",
            text: "erro ao cadastrar usuario",
            icon: "error",
            iconColor: "red",
            confirmButtonColor: "var(--azul)",
            confirmButtonText: "Confirmar",
          })
        })
    } else {
      Alert("Erro", "Email inválido")
    }
  }
  if (isActived == true) {
    return (
      <Contanier>
        <Card>
          <Header>
            <Title>Criação de Usuario</Title>
            <Buttons
              onClick={() => {
                setIsActived(false)
              }}
            >
              <Icone name={"X"} color={" #3a3f86"} size={36} />
            </Buttons>
          </Header>
          <Forms>
            <Input
              width="30vh"
              color="black"
              type={"text"}
              placeholdercolor="black"
              placeholder="Nome "
              required={"true"}
              event={(e) => {
                setNome(e.target.value)
              }}
            />
            <Input
              width="30vh"
              placeholdercolor="black"
              color="black"
              type={"email"}
              placeholder={"Email"}
              required={"true"}
              event={(e) => {
                setEmail(e.target.value)
              }}
            />
            <Input
              width="30vh"
              color="black"
              placeholdercolor="black"
              type={"password"}
              placeholder={"Senha"}
              required={"true"}
              event={(e) => {
                setSenha(e.target.value)
              }}
            />
          </Forms>
          <Button
            id={"button"}
            width="20vh"
            height="6vh"
            event={(e) => {
              e.preventDefault()
              HandleManualSubmit(e)
            }}
            placeholder="Criar Usuario "
          />
        </Card>
      </Contanier>
    )
  } else if (isActived == false) {
    return null
  }
}
